import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function SectionHeading({ heading, subheading, textStyleH2 }) {
  return (
    <Box mx="sideSpace">
      <Box as="h2" textStyle={textStyleH2 ? 'h2' : 'h3'} color="evergreen.2">
        {heading}
      </Box>
      {subheading && (
        <Text fontSize="lg" lineHeight="short">
          {subheading}
        </Text>
      )}
    </Box>
  );
}

SectionHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  textStyleH2: PropTypes.bool,
};

SectionHeading.defaultProps = {
  subheading: '',
  textStyleH2: false,
};

export default SectionHeading;
