import {
  Box, Grid, Text
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';

import ArrowLink from '../components/ArrowLink/ArrowLink';
import CollectionCard from '../components/CollectionCard/CollectionCard';
import Hero from '../components/Hero/Hero';
import LandingHeroLayout from '../components/layouts/landing-hero';
import ListView from '../components/listView';
import ObjectCard from '../components/ObjectCard/ObjectCard';
import SearchInput from '../components/SearchInput/SearchInput';
import SectionHeading from '../components/SectionHeading/SectionHeading';
import SocialMetadata from '../components/SocialMetadata/SocialMetadata';
import {
  fetchCollectionCounts, fetchFormattedNames, fetchObjectsByCatalogNumber,
} from '../lib/api';
import { FEATURED_COLLECTIONS, FEATURED_OBJECTS, HOMEPAGE_HERO_IMAGE } from '../lib/constants/managed';
import { collectionObjectType, formattedTaxonNamesType, taxonNamesType } from '../lib/propTypes';
import fetchTaxonNames from '../lib/server';

export async function getStaticProps({ locale }) {
  const counts = await fetchCollectionCounts();
  const featuredCollections = FEATURED_COLLECTIONS;
  const featuredObjects = await fetchObjectsByCatalogNumber(FEATURED_OBJECTS);

  const taxonKeys = featuredObjects.flatMap(({ taxonKey }) => taxonKey || []);
  // Get formatted scientific names (with italics)
  const formattedTaxonNames = await fetchFormattedNames(taxonKeys);
  // Get vernacular names
  const taxonNames = await fetchTaxonNames(taxonKeys, locale);

  if (!featuredCollections) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      counts,
      featuredCollections,
      featuredObjects,
      formattedTaxonNames,
      taxonNames,
      ...(await serverSideTranslations(locale, ['common'])),
    },
    revalidate: 3600,
  };
}

function HomepageHero({
  imageURL, imageAlt, imageFocusPoint, title, introText, advancedSearchInstruction,
}) {
  return (
    <Hero imageURL={imageURL} imageAlt={imageAlt} imageFocusPoint={imageFocusPoint} isHomepage>
      <Grid mt={{ base: '2', md: '5' }}>
        {/* Page heading */}
        <Text
          as="h1"
          textStyle="h1"
        >
          {title}
        </Text>

        {/* Intro text */}
        <Text textStyle="h8" maxWidth="54rem" mt={{ base: '8rem', md: '16rem' }}>{introText}</Text>

        {/* Search */}
        <Box maxWidth="30rem" mt={{ base: '10', md: '20' }}>
          <SearchInput id="homepage" prewarm />
          <Box mt={{ base: '10', md: '14' }}>
            <ArrowLink href="/search">{advancedSearchInstruction}</ArrowLink>
          </Box>
        </Box>
      </Grid>
    </Hero>
  );
}

HomepageHero.propTypes = {
  title: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
  advancedSearchInstruction: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
  imageAlt: PropTypes.string,
  imageFocusPoint: PropTypes.string,
};

HomepageHero.defaultProps = {
  imageURL: '',
  imageAlt: '',
  imageFocusPoint: 'left',
};

function Home({
  featuredCollections,
  featuredObjects,
  formattedTaxonNames,
  taxonNames,
  counts,
}) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const { src, imageFocusPoint, altDa, altEn } = HOMEPAGE_HERO_IMAGE;
  const heroImageURL = src;
  const { objects, collections } = counts;
  const commaSeparatedObjects = new Intl.NumberFormat().format(objects);
  const introText = t('headline', { commaSeparatedObjects, collections });

  const collectionCards = featuredCollections.map((item) => (
    <CollectionCard
      key={item.slug}
      item={item}
      includeRoundedCorners
    />
  ));

  const objectCards = featuredObjects.map((item) => {
    const formattedSpeciesName = item.taxonKey in formattedTaxonNames
      ? formattedTaxonNames[item.taxonKey]
      : null;

    return (
      <ObjectCard
        key={item.catalogNumber}
        item={item}
        titleHTML={formattedSpeciesName}
        vernacularName={taxonNames.find(
          (element) => element.id === item.taxonKey,
        )?.vernacular}
        headingLevel="h3"
        includeRoundedCorners
        includeSeeMore
      />
    );
  });

  return (
    <LandingHeroLayout
      heroComponent={(
        <HomepageHero
          title={t('collections')}
          introText={introText}
          advancedSearchInstruction={t('advanced-search-instruction')}
          imageURL={heroImageURL}
          imageAlt={locale === 'da' ? altDa : altEn}
          imageFocusPoint={imageFocusPoint}
        />
      )}
    >
      <SocialMetadata
        title={`${t('natural-history-museum-denmark')} ${t('title')}`}
        pathname={locale === 'da' ? '' : '/en'}
        description={introText}
      />

      {/* Featured collections */}
      <Box mt={20}>
        {/* Title */}
        <SectionHeading heading={t('featured-collections-heading')} textStyleH2 />
        {/* Cards */}
        <ListView columns={[1, null, 2]} spacing="0" styleProps={{ mt: [6, null, 12], borderBottom: "solid 1px #fff" }}>
          {collectionCards}
        </ListView>
        {/* View all link */}
        <ArrowLink href="/collection" mt={8} mx="sideSpace">{t('view-all-collections')}</ArrowLink>
      </Box>

      {/* Featured objects */}
      <Box py={20}>
        {/* Title */}
        <SectionHeading heading={t('featured-specimens-heading')} textStyleH2 />
        <ListView columns={[1, null, 2]} spacing="0" styleProps={{ mt: [6, null, 12], borderBottom: "solid 1px #fff" }}>
          {objectCards}
        </ListView>
      </Box>
    </LandingHeroLayout>
  );
}

Home.propTypes = {
  featuredCollections: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      label: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  featuredObjects: PropTypes.arrayOf(collectionObjectType),
  counts: PropTypes.shape({
    collections: PropTypes.number,
    objects: PropTypes.number,
  }),
  formattedTaxonNames: formattedTaxonNamesType,
  taxonNames: taxonNamesType,
};

Home.defaultProps = {
  featuredCollections: [],
  featuredObjects: [],
  formattedTaxonNames: [],
  taxonNames: [],
  counts: {
    collections: 0,
    objects: 0,
  },
};

export default Home;
