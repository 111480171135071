import {
  Box,
  Flex,
  Image,
  Text
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import NextChakraLink from '../NextChakraLink/NextChakraLink';

function CollectionCard({ item, includeRoundedCorners }) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const thumbnailURL = item.image;
  let thumbnailAltText;
  if (locale === 'da' && item.imageAltDa !== '') {
    thumbnailAltText = item.imageAltDa
  } else if (locale === 'en' && item.imageAltEn !== '') {
    thumbnailAltText = item.imageAltEn
  }

  return (
    <Box
      as="li"
      className={includeRoundedCorners ? 'rounded-corners' : ''}
      borderTop="1px"
      sx={{
        '@media (min-width: 48em)': {
          '&:nth-child(odd)': {
            borderRight: '1px'
          },
        },
      }}
    >
      <NextChakraLink
        href={`/collection/${item.slug}`}
        display="block"
        _focusVisible={{
          boxShadow: 'outlineCard',
        }}
        _hover={{
          color: 'greyHover',
          '& .arrow-link': {
            _after: {
              transform: 'translateX(2px)',
            }
          }
        }}
      >
        <Flex
          px="sideSpace"
          py={{ base: '5', md: 'sideSpace' }}
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: '4', sm: '8' }}
        >
          {/* IMAGE */}
          {thumbnailURL && (
            <Image
              src={thumbnailURL}
              alt={thumbnailAltText ?? ''}
              objectFit="cover"
              objectPosition={item.imageFocusPoint}
              aspectRatio="1.4955752212/1"
              width={{ base: '100%', sm: '50%' }}
              maxWidth={{ base: "226px", xl: "338px"}}
              maxHeight="226px"
              flexShrink={0}
            />
          )}

          {/* TEXT */}
          <Flex direction="column" justifyContent="space-between" flexShrink={1}>

            {/* Title */}
            <Text as="h3" textStyle="h3" wordBreak="break-word">
              {t(item.label)}
            </Text>

            {/* 'See more' */}
            <Text
              mt={{ base: '4', sm: '8' }}
              textStyle="link"
              className="arrow-link"
            >{t('see-more')}</Text>
          </Flex>
        </Flex>
      </NextChakraLink>
    </Box>
  );
}

CollectionCard.propTypes = {
  item: PropTypes.shape({
    slug: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.string,
    imageAltDa: PropTypes.string,
    imageAltEn: PropTypes.string,
    imageFocusPoint: PropTypes.string,
  }).isRequired,
  includeRoundedCorners: PropTypes.bool,
};

CollectionCard.defaultProps = {
  includeRoundedCorners: false,
};

export default CollectionCard;
